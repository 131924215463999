import React from 'react'
import { Root } from '../components/Root'
import { emails } from '../emails'
import { Button } from '../ui/buttons'
import { Card } from '../ui/cards'

const Request = () => (
  <Root>
    <h2>Request a Meeting</h2>
    <Card>
      <p>
        We bring AA meeting into hospitals, recovery centers, rehabs, and
        community facilities. Free big books can also be provided to those who
        need them. Meetings are generally set up for a specific day and time and
        repeat weekly.
      </p>
      <Button to={emails.requestMeeting}>Request a Meeting</Button>
    </Card>
  </Root>
)

export default Request
